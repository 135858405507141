import React, { Component } from 'react';
import './App.css';

import Input from './components/input';
import Cmb from './components/combo';
import Button from './components/button';
import Check from './components/check';
import { CalcGround } from './components/calcground.js';
import AppMesh from './components/AppMesh';
import './styles/styles.css'

class App extends Component {

  render() {

    return (
      <div>
        <AppMesh />
        <div id="footer" className="width">
        <i class="bi bi-info-circle"></i> Esta página irá cambiando a la medida que vamos implementando <br />  
          mas cálculos eléctricos relacionados con la norma IEEE-80 <i class="bi bi-exclamation-triangle-fill"></i><br />
          <br />© 2022 Copyright. All Rights Reserved. <br />
          <a href="javascript:void(0);">Privacy Policy</a>
        </div>
      </div>
    )

  }

}

export default App;