import React, { Component } from 'react';
//import { Component } from "react/cjs/react.production.min";
import '../../src/index.css'

class Combo extends Component {

  //function NumberList(props) {

  handleSelect = (e) => {
    this.props.handler(e.target.value)
  }


  render() {

    const items = this.props.items;
    const listItems = items.map((item) =>
      <option key={item.toString()} value={item.toString()}>
        {item}
      </option>
    );

    return (

      <div class="input-group mt-1 mb-1">
        <label class="input-group-text w-50" for="inputGroupSelect01">{this.props.title}</label>
        <select class="form-select" id="inputGroupSelect01" onChange={this.handleSelect}>
          {listItems}
        </select>
        <label class="input-group-text w-25" for="inputGroupSelect01">{this.props.Unit}</label>
      </div>

    )

  }

}

export default Combo;