export function CalcGround(LX, LY, D, h, Lrod, Nrods, Gau, IG, ts, rho, rho_s, hs, Weight, PerOk, Lx, Ly) {

    var Pi = Math.PI

    //Magnitud de la corriente rms a través del cuerpo
    var IB

    if (Weight == 50) {
        IB = 0.116 / Math.sqrt(ts);
    }
    else if (Weight == 70) {
        IB = 0.157 / Math.sqrt(ts);
    }

    //Longitud total de los conductores horizontales del rectangulo complementario de la L
    var Lcp = 2 * Lx * Ly / D

    //Longitud total de los conductores horizontales
    var Lc = 2 * LX * LY / D + LX + LY - Lcp

    //Longitud total de los electrodos
    var LR = Nrods * Lrod

    //Factor de reduccion de la capa superficial
    var Cs = 1 - ((0.09 * (1 - rho / rho_s)) / (2 * hs + 0.09))   //(27) Ecuación empirica
    //Cs = CalcCs(rho, rho_s, hs)
    Cs = Math.round(Cs * 100) / 100

    //Longitud efectiva de malla   (91)   (90)
    var LM = PerOk == true ? Lc + (1.55 + 1.22 * (Lrod / Math.sqrt(LX ** 2 + LY ** 2))) * LR : Lc + LR

    //Tensión de paso
    var Estep = (1000 + 6 * Cs * rho_s) * IB  //(28)

    //Tension de toque
    var Etouch = (1000 + 1.5 * Cs * rho_s) * IB  //(31)

    //Diametro del conductor de la malla
    var d_cond

    switch (Gau) {
        case "2": d_cond = 0.006543626; break;
        case "1": d_cond = 0.007348339; break;
        case "1/0": d_cond = 0.00935; break;
        case "2/0": d_cond = 0.01  /*05*/; break;
        case "3/0": d_cond = 0.0118; break;
        case "4/0": d_cond = 0.0133; break;
        case "250": d_cond = 0.0152; break;
        case "350": d_cond = 0.018; break;
        // default:
        //             break;
    }
    //Area de la malla
    var A = LX * LY - Lx * Ly

    //Longitud perimetral de la malla
    var Lp = 2 * LX + 2 * LY

    //Maxima distancia entre dos puntos de la malla
    var Dm = Math.sqrt(LX ** 2 + LY ** 2)

    var na, nb, nc, nd

    na = 2 * Lc / Lp                                //(85)
    nb = Math.sqrt(Lp / (4 * Math.sqrt(A)))         //(86)
    nc = (LX * LY / A) ** (0.7 * A / (LX * LY))     //(87)
    nd = Dm / Math.sqrt(LX ** 2 + LY ** 2)          //(88)

    var n = na * nb * nc * nd         //(84)
    n = Math.round(n * 10) / 10


    //Factor corrección para irregularidades de corriente
    var Ki = 0.644 + 0.148 * n        //(89)
    Ki = Math.round(Ki * 100) / 100

    var Kii = PerOk == true ? 1 : 1 / ((2 * n) ** (2 / n))  // (82)
    Kii = Math.round(Kii * 10) / 10

    var h0 = 1

    var Kh = Math.sqrt(1 + h / h0)    //(83)
    Kh = Math.round(Kh * 1000) / 1000

    //Factor de malla definido para n conductores paralelos
    var Km = (1 / (2 * Pi)) * (Math.log((D ** 2 / (16 * h * d_cond)) + ((D + 2 * h) ** 2 / (8 * D * d_cond)) - (h / (4 * d_cond)))
        + (Kii / Kh) * Math.log(8 / ((2 * n - 1) * Pi)))        //(81)

    Km = Math.round(Km * 100) / 100

    //Voltaje de malla
    var Em = rho * IG * Km * Ki / LM  //(80)
    //alert (Km + " " + Ki)

    //Factor de malla definido para n conductores paralelos
    var Ks = (1 / Pi) * (1 / (2 * h) + 1 / (D + h) + (1 / D) * (1 - 0.5 ** (n - 2)))      //(94)
    Ks = Math.round(Ks * 100) / 100

    var LS = 0.75 * Lc + 0.85 * LR    //(93)

    //Longitud total efectiva del sistema de puesta a tierra
    var LT = PerOk == true ? Lc + LR : Lc

    var Rg = (rho / 4) * Math.sqrt(Pi / A) + (rho / LT)                               //(51)

    Rg = rho * ((1 / LT) + (1 / Math.sqrt(20 * A)) * (1 + (1 / (1 + h * Math.sqrt(20 / A)))))     //(52)

    Rg = Rg.toFixed(2)

    //Gradiente de potencial
    var GPR = Math.round(IG * Rg)

    //alert(IG)
    //Tensión de paso
    var Es = rho * Ks * Ki * IG / LS  //(92)

    var ok = Es < Estep && Em < Etouch ? ok = true : ok = false

    // if (Es < Estep && Em < Etouch) {
    //     ok = "LA MALLA CUMPLE"
    // }
    // else {
    //     ok = "LA MALLA NO CUMPLE"
    // }

    //return ([Es, Em, Estep, Etouch, Rg, GPR, ok])
    return ({ Es: Es, Em: Em, Estep: Estep, Etouch: Etouch, Rg: Rg, GPR: GPR, LT: LT, Km: Km, Ki: Ki, Ks: Ks, n: n, Cs: Cs, ok: ok })

}

function CalcCs(Rho, Rho_s, hs) {

    var K = (Rho - Rho_s) / (Rho + Rho_s)
    K = Math.round(100 * K) / 100

    var S = 0

    for (let n = 1; n < 1000; n++) {
        S = S + ((K ** n) / Math.sqrt(1 + (2 * n * hs / 0.08) ** 2))
    }

    //return (1 / 0.96) * (1 + 2* S)
    return (1 + 16 * 0.08 * S / Rho_s)

}