import React, {Component} from 'react';
import Result from './result';

class Button extends Component {

  handleClick = () => {
    this.props.handler()
  }

  render() {
    return (
      <div>
      <button onClick={this.handleClick}>
       {this.props.text}
      </button>
      </div>
    );
  }
}

export default Button;