import React, { Component }  from 'react';
//import react from "react";
//import { Component } from "react/cjs/react.production.min";
//import 'D:/Desarrollos/CalculosElectricos/app/src/index.css';
import '../../src/index.css';

class Check extends Component {

//function NumberList(props) {

state = {value: this.props.defaultChecked}

handleChange = ()=>{
  this.setState({value: !this.state.value})
  this.props.handler(!this.state.value)
}

render() {

    return (
     <div className="cont_inputs">
       <div Style="width:250px">{this.props.title}</div>
       <div Style="width:100px float:right"><input type="checkbox" defaultChecked={this.props.defaultChecked} onChange={this.handleChange}/></div>
       <div Style="width:100px">{this.props.Unit}</div>
     </div>
    );
  
  }

}

export default Check;