import React, { Component } from 'react';
//import './App.css';

import Input from './input';
import CmbCable from './cmbCable';
import CmbWeigth from './combo';
import Button from './button';
import Check from './check';
import { CalcGround } from './calcground.js';
//import 'D:/Desarrollos/CalculosElectricos/app/src/index.css';

class AppMesh extends Component {

  LX = 70
  LY = 70
  Lx = 0
  Ly = 0
  D = 7
  H = 0.5
  Lrod = 7.5
  Nrods = 0
  Gau = "2/0"
  IG = 1908
  ts = 0.5
  Rho = 400
  Rho_s = 2500
  Hs = 0.102
  Weight = 70
  PerOk = true
  Tab = 'square'

  state = {
    Es: 0, Em: 0, Estep: 0, Etouch: 0, Rg: 0, GPR: 0, LT: 0, Km: 0, Ki: 0, Ks: 0, n: 0, Cs: 0, Class: 'alert alert-danger',
    Msg: 'Las tensiones de paso y contacto NO cumplen'
  };

  Calculate = () => {
    var LX = parseFloat(this.LX)
    var LY = parseFloat(this.LY)
    var D = parseFloat(this.D)
    var H = parseFloat(this.H)
    var Lrod = parseFloat(this.Lrod)
    var Nrods = parseInt(this.Nrods)
    var Gau = this.Gau
    var IG = parseFloat(this.IG)
    var ts = parseFloat(this.ts)
    var Rho = parseFloat(this.Rho)
    var Rho_s = parseFloat(this.Rho_s)
    var Hs = parseFloat(this.Hs)
    var Weight = parseInt(this.Weight)
    var PerOk = this.PerOk
    var Lx = this.Tab == 'square' ? 0 : this.Tab == 'rectangle' ? 0 : this.Tab == 'L' ? this.Lx : 0
    var Ly = this.Tab == 'square' ? 0 : this.Tab == 'rectangle' ? 0 : this.Tab == 'L' ? this.Ly : 0

    var Res = CalcGround(LX, LY, D, H, Lrod, Nrods, Gau, IG, ts, Rho, Rho_s, Hs, Weight, PerOk, Lx, Ly)

    if (Res.ok) {
      this.setState({ Class: 'alert alert-success', Msg: 'Las tensiones de paso y contacto cumplen' })
    }
    else {
      this.setState({ Class: 'alert alert-danger', Msg: 'Las tensiones de paso y contacto NO cumplen' })
    }

    this.setState({
      Es: Res.Es, Em: Res.Em, Estep: Res.Estep, Etouch: Res.Etouch, Rg: Res.Rg,
      GPR: Res.GPR, LT: Res.LT, Km: Res.Km, Ki: Res.Ki, Ks: Res.Ks, n: Res.n, Cs: Res.Cs
    })
  }

  handLX = (LX) => { this.LX = LX; this.Calculate() }
  handLY = (LY) => { this.LY = LY; this.Calculate() }
  handLx = (Lx) => { this.Lx = Lx; this.Calculate() }
  handLy = (Ly) => { this.Ly = Ly; this.Calculate() }
  handD = (D) => { this.D = D; this.Calculate() }
  handH = (H) => { this.H = H; this.Calculate() }
  handLrod = (Lrod) => { this.Lrod = Lrod; this.Calculate() }
  handNrods = (Nrods) => { this.Nrods = Nrods; this.Calculate() }
  handGau = (Gau) => { this.Gau = Gau; this.Calculate() }
  handIG = (IG) => { this.IG = IG; this.Calculate() }
  handts = (ts) => { this.ts = ts; this.Calculate() }
  handRho = (Rho) => { this.Rho = Rho; this.Calculate() }
  handRho_s = (Rho_s) => { this.Rho_s = Rho_s; this.Calculate() }
  handHs = (Hs) => { this.Hs = Hs; this.Calculate() }
  handWeight = (Weight) => { this.Weight = Weight; this.Calculate() }
  handPer = (PerOk) => { this.PerOk = PerOk; this.Calculate() }

  handShape = (e) => {
    switch (e.target.id) {
      case 'nav-square': this.Tab = 'square'; break;
      case 'nav-rectangle': this.Tab = 'rectangle'; break;
      case 'nav-L': this.Tab = 'L'; break;
    }
    this.Calculate()
  }

  render() {

    return (
      <div class='container-fluid'>

        <div class='row border bg-light' Style='height:40px'><h3 Style='text-align:center'>Cálculo de malla</h3></div>

        <div class='row border bg-light'>

          <div class='col'>

            <div class="card">
              <div class="card-header">
                <strong>Dimensiones</strong>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="nav-square"
                        data-bs-toggle="tab"
                        data-bs-target="#square"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        onClick={this.handShape}>
                        Cuadrada
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="nav-rectangle"
                        data-bs-toggle="tab"
                        data-bs-target="#rectangle"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={this.handShape}>
                        Rectangular
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="nav-L"
                        data-bs-toggle="tab"
                        data-bs-target="#L"
                        // data-bs-target="#contact"
                        type="button"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={this.handShape}>
                        En L
                      </button>
                    </li>
                  </ul>

                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="square" role="tabpanel" aria-labelledby="home-tab">
                      <Input title="Lado" id="Lx" DefVal={this.LX} Step={1} min={1} Unit="[m]" handler={this.handLX} />
                      <Input title="Lado cuadricula" id="D" DefVal={this.D} Unit="[m]" handler={this.handD} />
                    </div>
                    <div class="tab-pane fade" id="rectangle" role="tabpanel" aria-labelledby="profile-tab">
                      <Input title="Longitud X" id="Lx" DefVal={this.LX} Step={1} min={1} Unit="[m]" handler={this.handLX} />
                      <Input title="Longitud Y" id="Ly" DefVal={this.LY} Step={1} min={1} Unit="[m]" handler={this.handLY} />
                      <Input title="Lado cuadricula" id="D" DefVal={this.D} Unit="[m]" handler={this.handD} />
                    </div>
                    <div class="tab-pane fade" id="L" role="tabpanel" aria-labelledby="contact-tab">
                      <Input title="Longitud rectangulo mayor X" id="Lx" DefVal={this.LX} Step={1} min={1} Unit="[m]" handler={this.handLX} />
                      <Input title="Longitud rectangulo mayor Y" id="Ly" DefVal={this.LY} Step={1} min={1} Unit="[m]" handler={this.handLY} />
                      <Input title="Longitud rectangulo menor X" id="Lx" DefVal={this.Lx} Step={1} min={1} Unit="[m]" handler={this.handLx} />
                      <Input title="Longitud rectangulo menor Y" id="Ly" DefVal={this.Ly} Step={1} min={1} Unit="[m]" handler={this.handLy} />
                      <Input title="Lado cuadricula" id="D" DefVal={this.D} Unit="[m]" handler={this.handD} />
                      <Input title="LT" DefVal={Math.round(this.state.LT)} Unit="[m]" />
                    </div>
                  </div>

                </li>
              </ul>
            </div>

            <div class="card">
              <div class="card-header">
                <strong>Calculos intermedios</strong>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item"><Input title="Km" DefVal={Math.round(1000 * this.state.Km) / 1000} Unit="[ ]" /></li>
                <li class="list-group-item"><Input title="Ki" DefVal={Math.round(1000 * this.state.Ki) / 1000} Unit="[ ]" /></li>
                <li class="list-group-item"><Input title="Ks" DefVal={Math.round(1000 * this.state.Ks) / 1000} Unit="[ ]" /></li>
                <li class="list-group-item"><Input title="n" DefVal={Math.round(1000 * this.state.n) / 1000} Unit="[ ]" /></li>
                <li class="list-group-item"><Input title=" Factor de reducción Cs" DefVal={this.state.Cs} Unit="[ ]" /></li>
              </ul>
            </div>

          </div>

          <div class='col'>

            <div class="card">
              <div class="card-header">
                <strong>Conductores</strong>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item"><Input title="Profundidad conductor" id="h" DefVal={this.H} Step={0.1} Unit="[m]" handler={this.handH} /></li>
                <li class="list-group-item"><Input title="Longitud varillas" id="Lrod" DefVal={this.Lrod} Step={0.1} Unit="[m]" handler={this.handLrod} /></li>
                <li class="list-group-item"><Input title="Numero varillas" id="Nrods" DefVal={this.Nrods} Unit="[unid]" handler={this.handNrods} /></li>
                <li class="list-group-item"><CmbCable title="Calibe conductor" Unit="[AWG]" handler={this.handGau} /></li>
                <li class="list-group-item"><Check title="perimetro" handler={this.handPer} defaultChecked={true} /></li>
              </ul>
            </div>

            <div class="card">
              <div class="card-header">
                <strong>Parametros de falla</strong>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item"><Input title="Maxima corriente de falla" id="IG" DefVal={this.IG} Unit="[A]" handler={this.handIG} /></li>
                <li class="list-group-item"><Input title="Duración corriente de choque" id="ts" DefVal={this.ts} Step={0.001} Unit="[s]" handler={this.handts} /></li>
                <li class="list-group-item"><CmbWeigth title="Peso del cuerpo" items={[50, 70]} Unit="[kg]" handler={this.handWeight} /></li>
              </ul>
            </div>

            <div class="card">
              <div class="card-header">
                <strong>Terreno</strong>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item"><Input title="Resistividad del terreno" id="Rho" DefVal={this.Rho} Unit="[m]" handler={this.handRho} /></li>
                <li class="list-group-item"><Input title="Resistividad material superficie" id="Rho_s" DefVal={this.Rho_s} Unit="[m]" handler={this.handRho_s} /></li>
                <li class="list-group-item"><Input title="Altura capa superficial" id="Hs" DefVal={this.Hs} Unit="[m]" handler={this.handHs} /></li>
              </ul>
            </div>

          </div>

          <div class='col'>

            <div class="card">
              <div class="card-header">
                <strong>Tensiones tolerables</strong>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item"><Input title="Tension de paso" DefVal={Math.round(10 * this.state.Estep) / 10} Unit="[V]" /></li>
                <li class="list-group-item"><Input title="Tension de toque" DefVal={Math.round(10 * this.state.Etouch) / 10} Unit="[V]" /></li>
              </ul>
            </div>

            <div class="card">
              <div class="card-header">
                <strong>Tensiones calculadas</strong>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item"><Input title="Tension de paso" DefVal={Math.round(this.state.Es)} Unit="[V]" /></li>
                <li class="list-group-item"><Input title="Tension de malla" DefVal={Math.round(10 * this.state.Em) / 10} Unit="[V]" /></li>
              </ul>
            </div>

            <div class="card">
              <div class="card-header">
                <strong>Resistencia y GPR</strong>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <Input title="Resistencia de puesta a tierra" DefVal={this.state.Rg} Unit="[Ohm]" />
                </li>
                <li class="list-group-item">
                  <Input title="Gradiente de potencial" DefVal={this.state.GPR} Unit="[V]" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    )

  }

}

export default AppMesh;