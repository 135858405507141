import React, {Component, useState} from 'react';

function Result() {

  const [count, setCount] = useState(0);

  return (
    <div>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
    </div>
  );

}
  

// class Result extends Component {

//   render() {
//     return <div>
//       <p>Resultado</p>
//     </div>
//   }

// }   

export default Result;