import React, { Component } from 'react';
//import 'D:/Desarrollos/CalculosElectricos/app/src/index.css';

class Input extends Component {

  handleChange = (e) => {
    this.props.handler(e.target.value)
  }

  render() {
    return (

      //  <div className="cont_inputs">
      //   <div Style="width:300px"><label for={this.props.id}>{this.props.title}</label></div>
      //   <div Style="width:100px">
      //     <input className='textbox' id={this.props.id} type="number" Style="text-align:right" Value={this.props.DefVal}
      //       min={this.props.min} step={this.props.Step} onChange={this.handleChange} required />
      //   </div>
      //   <div Style="width:30px">{this.props.Unit}</div>
      // </div> 

      <div class="input-group">
        <label class="input-group-text w-50" for={this.props.id}>{this.props.title}</label>
        <input type="text" aria-label="First name" class="form-control" id={this.props.id} Value={this.props.DefVal}
          min={this.props.min} step={this.props.Step} onChange={this.handleChange} required />
        <label class="input-group-text">{this.props.Unit}</label>
      </div>

    )
  }

}

export default Input;