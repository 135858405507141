import React, { Component }  from 'react';
//import { Component } from "react/cjs/react.production.min";
import Combo from "./combo";

export default class CmbCable extends Component {

    render() {

        return(
            <Combo title={this.props.title} items={["2","1/0","2/0","3/0","4/0"]} Unit={this.props.Unit} handler={this.props.handler}></Combo>
        )

    }

}
